angular.module('sq.jobs', [
  'sq.jobs.queue',
  'sq.jobs.forms',
  'sq.jobs.results',
  'sq.jobs.public',
  // Require events early, so that they can listen for socketIO events
  'sq.jobs.events.service',
  'sq.jobs.upload.toS3.service',
  'sq.jobs.upload.service',
  'sq.jobs.tracker.service',
  'sq.jobs.submit.service',
]);
